<!-- eslint-disable no-unused-vars -->
<template>
  <b-card title="Editar" class="text-left">
    <b-form>
      <!-- Código do projeto -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label
              for="project_code"
              class="text-center font-weight-bold text-primary"
            >
              Código do projeto :
            </label>
            <BFormInput
              id="project_code"
              type="text"
              v-model="item.project_code"
            ></BFormInput>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Nome do projeto -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label
              for="project_name"
              class="text-center font-weight-bold text-primary"
            >
              Nome do projeto :
            </label>
            <BFormInput
              id="project_name"
              type="text"
              v-model="item.project_name"
            ></BFormInput>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Nome do cliente -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label
              for="client_name"
              class="text-center font-weight-bold text-primary"
            >
              Nome do cliente :
            </label>
            <BFormInput
              id="client_name"
              type="text"
              v-model="item.client_name"
            ></BFormInput>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Tipo do projeto -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label class="text-center font-weight-bold text-primary"
              >Tipo de Pessoa:</label
            >
            <b-form-radio-group v-model="item.project_type">
              <b-form-radio value="pessoa_fisica">Pessoa Física</b-form-radio>
              <b-form-radio value="pessoa_juridica"
                >Pessoa Jurídica</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Documento cliente -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label
              for="document"
              class="text-center font-weight-bold text-primary"
            >
              CPF / CNPJ :
            </label>
            <BFormInput
              id="document"
              type="text"
              v-model="item.document"
            ></BFormInput>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Endereço -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="description" class="font-weight-bold text-primary"
              >Endereço :</label
            >
            <BFormTextarea
              id="description"
              type="textarea"
              v-model="item.address"
            ></BFormTextarea>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Nome do contato -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label
              for="contact_name"
              class="text-center font-weight-bold text-primary"
            >
              Nome do contato :
            </label>
            <BFormInput
              id="contact_name"
              type="text"
              v-model="item.contact_name"
            ></BFormInput>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Documento do contato -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label
              for="contact_document"
              class="text-center font-weight-bold text-primary"
            >
              CPF do contato :
            </label>
            <BFormInput
              id="contact_document"
              type="text"
              v-model="item.contact_document"
            ></BFormInput>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Telefone do contato -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label
              for="contact_phone"
              class="text-center font-weight-bold text-primary"
            >
              Telefone do contato :
            </label>
            <BFormInput
              id="contact_phone"
              type="tel"
              v-model="item.contact_phone"
            ></BFormInput>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Descrição do projeto -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label
              for="project_description"
              class="font-weight-bold text-primary"
              >Descrição do projeto :</label
            >
            <BFormTextarea
              id="project_description"
              type="textarea"
              v-model="item.project_description"
            ></BFormTextarea>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Prazo -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label
              for="deadline"
              class="text-center font-weight-bold text-primary"
            >
              Prazo :
            </label>
            <b-input-group prepend="DIAS">
              <BFormInput
                id="deadline"
                type="number"
                v-model="item.deadline"
              ></BFormInput>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Data Inicio -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="date" class="font-weight-bold text-primary"
              >Data de inicio :</label
            >
            <BFormInput
              id="start_date"
              type="date"
              v-model="item.start_date"
            ></BFormInput>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Data do contrato -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="contract_date" class="font-weight-bold text-primary"
              >Data do contrato :</label
            >
            <BFormInput
              id="contract_date"
              type="date"
              v-model="item.contract_date"
            ></BFormInput>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Previsao de conclusao -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label for="finish_date" class="font-weight-bold text-primary"
              >Previsão de conclusão :</label
            >
            <BFormInput
              id="finish_date"
              type="date"
              v-model="item.finish_date"
            ></BFormInput>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Valor -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label
              for="project_value"
              class="text-center font-weight-bold text-primary"
            >
              Valor do projeto :
            </label>
            <b-input-group prepend="R$">
              <BFormInput
                @input="handleInputChange()"
                id="project_value"
                type="text"
                v-model="item.project_value"
              ></BFormInput>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Imposto -->
      <b-row class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center">
        <b-col col xs="12" sm="12" md="6" lg="6">
          <b-form-group>
            <label
              for="taxes"
              class="text-center font-weight-bold text-primary"
            >
              Imposto :
            </label>
            <b-form-select
              id="taxes"
              v-model="item.tax_id"
              :options="taxes"
              value-field="id"
              text-field="name"
              placeholder="Selecione uma taxa"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <div class="justify-center">
      <BButton variant="outline" @click="$router.push('/lancamento-de-receita')"
        >Voltar</BButton
      >
      <BButton @click="updateItem">Salvar</BButton>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BCol,
  BRow,
  BFormRadioGroup,
  BFormRadio,
  BFormSelect,
  BInputGroup,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { VBTooltip } from 'bootstrap-vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BFormInput,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormRadioGroup,
    BFormRadio,
    BFormSelect,
    BButton,
    BInputGroup,
  },
  data: () => ({
    haveInstallments: false,
    item: { project_name: '', transaction_date: '01/01/2023' },
    installments: [],
    projects: [],
    taxes: [],
    date: '21/08/2023',
    statusOptions: ['previsto', 'realizado'],
  }),

  created() {
    this.getItem();
    this.getAllTaxes();
  },

  computed: {},

  methods: {
    async getItem() {
      this.$store
        .dispatch('getProject', {
          id: this.$route.params.id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          const data = {
            ...resp,
            contract_date: this.dateFormatter(resp.contract_date, 'yyyy-MM-dd'),
            created_at: this.dateFormatter(resp.created_at, 'yyyy-MM-dd'),
            updated_at: this.dateFormatter(resp.updated_at, 'yyyy-MM-dd'),
            start_date: this.dateFormatter(resp.start_date, 'yyyy-MM-dd'),
            finish_date: this.dateFormatter(resp.finish_date, 'yyyy-MM-dd'),
            project_value: this.numberToMonetary(resp.project_value),
          };

          this.item = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async updateItem() {
      this.$store
        .dispatch('updateProject', {
          ...this.item,
          project_value: this.monetaryToNumber(this.item.project_value),
          reference_date: this.item.reference_date,
          transaction_date: this.item.transaction_date,
          status: this.item.status,
          description: this.item.description,
          id: this.item.id,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Projeo alterada com sucesso.',
              icon: 'SuccessIcon',
              variant: 'success',
            },
          });
          this.$router.push({
            name: 'projetos',
          });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao alterar receita.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },

    getAllTaxes() {
      this.taxes = [];
      this.$store
        .dispatch('getAllTaxes', {
          inactive: false,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          if (resp) {
            resp.map((e) => {
              this.taxes.push({ id: e.id, name: e.tax_name });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async handleInputChange() {
      let valor = this.item.project_value;

      // Remove caracteres não numéricos
      valor = valor.replace(/\D/g, '');

      // Formata o valor como moeda
      valor = (Number(valor) / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });

      // Atualiza o valor no input
      this.item.project_value = valor.replace('R$', '');
    },
  },
};
</script>
<style>
.justify-center {
  display: flex;
  justify-content: center;
}
td::before {
  float: none !important;
}
</style>
